//Libraries
import React, { FunctionComponent } from 'react'

//Types
import { AnnouncementSectionTypes } from '../z_components-types'

//Styles
import styles from './css/announcementSection.module.scss'

const AnnouncementSection: FunctionComponent<AnnouncementSectionTypes> = ({
  data,
}) => {
  const { node } = data
  const { frontmatter } = node
  const date = new Date(frontmatter.date)
  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ][date.getMonth()]
  const FormattedDate = `${month} ${date.getDay()}, ${date.getFullYear()}, ${
    date.getHours() > 12 ? date.getHours() - 12 : date.getHours()
  }:${date.getMinutes()}${date.getHours() > 12 ? 'PM' : 'AM'}`

  return (
    node.html ? (
      <article
        id={frontmatter.title.split(' ').join('-').toLowerCase()}
        className={styles.announcement}
      >
        <span className={styles.date}>{FormattedDate}</span>
        <h3 className={styles.title}>{`${frontmatter.title} `}</h3>
        <hr />
        <div dangerouslySetInnerHTML={{ __html: node.html }}></div>
      </article>
    ) : (
      <></>
    )
  )
}

export default AnnouncementSection
