//Libraries
import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

//Context
import { FontSizeProvider } from '../context/fontSizeContext'

//Types
import { AnnouncementType, AnnouncementsTypes } from './z_page-types'

//Styles
import styles from './css-pages/pages.module.scss'

//Components
import Header from '../components/header/header'
import Layout from '../components/layout/layout'
import Footer from '../components/footer/footer'
import PageWelcome from '../components/header/pageWelcome'
import AnnouncementSection from '../components/templates/announcementSection'
import PageBackground from '../components/free/pageBackground'

//Static
import { compareDates } from '../../static/reuseableFunctions'

const Announcements: FunctionComponent<AnnouncementsTypes> = ({ data }) => {
  //Filters out placeholder announcement
  const announcements = data.allMarkdownRemark.edges.filter(
    announcement => announcement.node.frontmatter.title !== 'placeholder'
  )

  return (
    <FontSizeProvider>
        <Helmet htmlAttributes={{lang: 'en'}}>
          <meta charSet="utf-8" />
          <title>OOA - Announcements</title>
          <link rel="canonical" href="https://ooa.csi.edu/announcements/" />
          <meta
            name="description"
            content="Announcements of anything new or important pertaining to the Office on Aging."
          />
        </Helmet>

      <Header />

      <Layout>
        <PageWelcome
          img={data.markdownRemark.frontmatter.img}
          title={data.markdownRemark.frontmatter.title}
          imgAltText="Announcements banner image"
        />

        {/*
          Checks if there is any announcements in addition to the placeholder announcements
          If there is, it will render normal, if not, it will render the no announcements text
        */}

        {announcements.length > 0 ? (
          announcements
            .sort((a, b) => 
              compareDates(a.node.frontmatter.date, b.node.frontmatter.date)
            ).reverse()
            .map((announcement: AnnouncementType, index: number) => (
              <AnnouncementSection key={index} data={announcement} />
            ))
        ) : (
          <h2 className={styles.noEvents}>
            There are no announcements at this time
          </h2>
        )}
      </Layout>

      <Footer />
      <PageBackground />
    </FontSizeProvider>
  )
}

export default Announcements

export const announcementData = graphql`
  query announcementsQuery {
    markdownRemark(
      frontmatter: { template_key: { eq: "announcements-page" } }
    ) {
      frontmatter {
        title
        img {
          childImageSharp {
            fluid(maxWidth: 1700, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { template_key: { eq: "announcement" } } }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            linkText
            link
            date
            img {
              childImageSharp {
                fluid(maxWidth: 1700, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
